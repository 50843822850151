<template>
  <div id="app">
    <transition
      :name="transitionDirection"
      :css="
        !this.displayOtherConnectionNotification &&
        !this.displayLostConnectionNotification
      "
      mode="out-in"
      @after-leave="resetScreen"
    >
      <component
        :is="activeScreenRef.template"
        v-if="
          activeScreenRef != null &&
          this.$options.components[activeScreenRef.template] != null
        "
        :key="this.getTemplateId()"
        v-bind="this.componentsToProps(activeScreenRef.components)"
        v-bind:customComponents="this.getCustomComponents()"
      ></component>
      <GenericTemplate
        v-else-if="activeScreenRef != null"
        :key="'fallback-' + this.getTemplateId()"
        v-bind="activeScreenRef.components"
        v-bind:customComponents="this.getCustomComponents()"
      ></GenericTemplate>
      <Loader v-else></Loader>
    </transition>
    <div class="legal">
      <a target="_blank" href="https://www.frankfurt-school.de/en/home/imprint"
        >Imprint</a
      >
      <a target="_blank" href="https://www.frankfurt-school.de/en/home/legal"
        >Legal statement</a
      >
      <a
        target="_blank"
        href="https://www.frankfurt-school.de/en/home/data-protection"
        >Data protection</a
      >
    </div>
    <div
      id="lost-connection-notification"
      class="modal"
      :class="{ 'is-active': this.displayLostConnectionNotification }"
    >
      <div class="modal-background"></div>
      <div class="modal-card">
        <section class="modal-card-body">
          <p class="card-header-title">
            Connection lost. Please check your internet connection, or try again
            later.
          </p>
          <button
            type="button"
            class="button is-primary is-fullwidth"
            @click="reload()"
          >
            Click to reload
          </button>
        </section>
      </div>
    </div>
    <div
      id="other-connection-notification"
      class="modal"
      :class="{ 'is-active': this.displayOtherConnectionNotification }"
    >
      <div class="modal-background"></div>
      <div class="modal-card">
        <section class="modal-card-body">
          <p class="card-header-title">
            This application appears to be open in another tab.
          </p>
          <button
            type="button"
            class="button is-primary is-fullwidth"
            @click="establishWebsocketConnection(0)"
          >
            Click to switch here
          </button>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Armory from "@mindsmiths/armory-sdk/src/Armory";
import NPSRating from "@/components/NPSRating.vue";
import LargeTitle from "@/components/LargeTitle.vue";
import PDFScreen from "@/components/PDFScreen.vue";
import EmailValidator from "@/components/EmailValidator.vue";
import VerificationCode from "@/components/VerificationCode.vue";
import FadedDescription from "@/components/FadedDescription.vue";
import EmailInputWithAddon from "@/components/EmailInputWithAddon.vue";
import InputWithAddon from "@/components/InputWithAddon.vue";
import MultiselectDropdown from '@/components/MultiselectDropdown.vue'


export default {
  name: "App",
  extends: Armory,
  methods: {
    getCustomTemplates() {
      return {};
    },
    getCustomComponents() {
      return {
        NPSRating,
        LargeTitle,
        PDFScreen,
        VerificationCode,
        EmailValidator,
        FadedDescription,
        EmailInputWithAddon,
        InputWithAddon,
        MultiselectDropdown
      };
    },
    // eslint-disable-next-line no-unused-vars
    preReceivedSpecificationTrigger(receivedData) {},
    postReceivedSpecificationTrigger() {},
  },
  watch: {
    activeScreen(newScreen, oldScreen) {
      if (newScreen !== oldScreen) {
        if (newScreen.id === "onboarding.input-verification-code-try-again") {
          this.transitionDirection = "";
        } else {
          this.transitionDirection =
              this.$store.state.transitionDirection === "right"
                  ? "template-right"
                  : "template-left";
        }
        this.activeScreenRef = newScreen;
        this.$hj("stateChange", newScreen.id);
      }
    },
  },
};
</script>

<style scoped>
.template-right-leave-active,
.template-right-enter-active,
.template-left-leave-active,
.template-left-enter-active {
  transition: 0.5s;
}

.template-right-enter {
  opacity: 0;
  transform: translateX(100%);
}

.template-right-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.template-left-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.template-left-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

#app {
  padding: 20px 30px 30px 30px;
  flex-direction: column;
  align-items: center;
}

.legal {
  text-align: center;
  margin-top: 20px;
}
.legal > a {
  transition: opacity 200ms ease;
  font-weight: 400;
  font-size: 0.8rem;
  text-decoration: none !important;
  color: gray !important;
}

.legal > a:not(:last-child) {
  margin-right: 20px;
}

.card-header-title {
  font-size: 1.2rem;
  font-weight: 600;
}

.modal-card {
  border-radius: 10px;
}

.card-header-title {
  margin-bottom: 1rem;
}
</style>
